.separator-wrapper {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    margin-top: -50px;
}

.separator-straight, .separator-biais, .separator-waves, .separator-curves {
    display: block;
    width: 100%;
    height: 50px;
}

.separator-biais-reversed {
    display: block;
    width: 100%;
    height: 50px;
    transform: rotateY(180deg);
}

.separator-wrapper-special {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    line-height: 0;
    margin-top: -50px;
    z-index: 0;
}

.separatorWS {
    display: block;
    width: 100%;
}

.separatorWS path {
    transform: translateY(-350px);
}
